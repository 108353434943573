<template>
<el-form ref="form" v-if="user" :model="user" label-width="80px" size="mini">
    <h3>{{msg('用户基本信息')}}</h3>
    <el-row>
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
            <el-form-item :label="msg('用户名称')">
                <el-input v-model.trim="user.fullname" autocomplete="off" @input="changeFullname(user.fullname)"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="type==2">
            <el-form-item :label="msg('用户账号')">
                <el-input v-model.trim="user.username" autocomplete="off"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
            <el-form-item :label="msg('密码')">
                <el-input type="password" v-model.trim="user.password" show-password autocomplete="off"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
            <el-form-item :label="msg('确认密码')">
                <el-input type="password" v-model.trim="user.repassword" show-password autocomplete="off"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="type===0 || type===2" >
            <el-form-item :label="msg('状态')">
                <el-select v-model="user.status" class="query-select">
                    <el-option v-for="d in userEditStatuses" 
                        :label="d.label" :value="d.value" :key="d.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <!-- <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="hasPermission('user:testVip') && type==2">
            <el-form-item label-width="10px">
                <el-checkbox :true-label="1" :false-label="0" v-model="user.testVip">{{msg('1个月试用期')}}</el-checkbox>
            </el-form-item>
        </el-col> -->
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="8"  :md="6" :lg="6">
            <el-form-item :label="msg('角色')">
                <el-select v-model="user.roleId" class="query-select" @change="selectedRole">
                    <el-option v-for="role in roles" 
                        :label="role.label" :value="role.id" :key="role.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="type==2">
            <el-form-item>
                <el-checkbox @change="changeAutoDept" v-model="autoDept">{{msg('自动创建下级组织')}}</el-checkbox>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-show="deptLabelShow">
            <el-form-item :label="msg('所在组织')">
                <el-input v-if="!hasPermission('user:deptEdit') && type != 2" :disabled="true" v-model="user.deptLabel"></el-input>
                <el-input v-else v-model="user.deptLabel" v-popover:deptPopover suffix-icon="el-icon-arrow-down" :disabled="!hasPermission('user:deptEdit') && type != 2"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="hasPermission('user:vipKey') && type == 0">
            <el-form-item :label="msg('密钥')">
                <el-input v-model.trim="user.vipKey" autocomplete="off"></el-input>
                <el-select v-model="user.vipKeyType" class="query-select" clearable @clear="clearVipKeyType" @change="selectVipKey">
                    <el-option v-for="d in vipKeyTypes" 
                        :label="d.label" :value="d.value" :key="d.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="hasPermission('user:locked')">
            <el-form-item>
                <el-checkbox v-model="locked">{{msg('锁定')}}</el-checkbox>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
            <el-form-item :label="msg('架位号')">
                <el-input v-model.trim="user.shelfCode" :disabled="!hasPermission('user:profileEdit')">
                </el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="hasPermission('user:weightShow')">
            <el-form-item :label="msg('附加重量')">
                <el-input v-model="user.weight" type="number">
                     <template slot="append">KG</template>
                </el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="hasPermission('user:userTag')">
            <el-form-item :label="msg('用户标识')">
                <el-input type="text" v-model.trim="user.userTag"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="hasPermission('user:userPkgType')">
            <el-form-item :label="msg('默认打包类型')">
                <el-select v-model="user.pkgType" class="query-select">
                    <el-option v-for="role in this.dicts.userPkgTypes" 
                        :label="role.label" :value="role.value" :key="role.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="hasPermission('user:userPageTag')">
            <el-form-item :label="msg('面单标识')">
                <el-input type="text" v-model.trim="user.pageTag" placeholder="只能输入一个字符"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
            <el-form-item :label="msg('面单标识2')" v-if="hasPermission('user:userStoreTag')">
                <el-input type="text" v-model.trim="user.storeTag" placeholder="只能输入一个字符"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
            <el-form-item>
                <el-checkbox v-model="rebackChecked" :disabled="!hasPermission('user:showRebacked')">{{msg('开启退件拆包转寄')}}</el-checkbox>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6" v-if="hasPermission('user:freeShop')">
            <el-form-item>
                <el-checkbox v-model="freeShop" :disabled="!hasPermission('user:showRebacked')">{{msg('赠送店铺')}}</el-checkbox>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="18" :sm="6" :md="6" :lg="4" v-if="hasPermission('user:vipBuyType1')">
            <el-form-item>
                <el-checkbox v-model="vip1Buy" :disabled='vipBuyDisabled' @change='clickVipBuy'>{{msg('普通会员')}}</el-checkbox>
            </el-form-item>
        </el-col>
        <el-col :xs="18" :sm="6" :md="6" :lg="4" v-if="hasPermission('user:vipBuyType2')">
            <el-form-item>
                <el-checkbox v-model="vip2Buy" :disabled='vipBuyDisabled' @change='clickVipBuy'>{{msg('高级会员')}}</el-checkbox>
            </el-form-item>
        </el-col>
        <el-col :xs="18" :sm="6" :md="6" :lg="4" v-if="hasPermission('user:vipBuyType2')">
            <el-form-item>
                <el-checkbox v-model="vip3Buy" :disabled='vipBuyDisabled' @change='clickVipBuy'>{{msg('超级会员')}}</el-checkbox>
            </el-form-item>
        </el-col>
        <el-col :xs="18" :sm="6" :md="6" :lg="4" v-if="hasPermission('user:vipBuyType2')">
            <el-form-item>
                <el-checkbox v-model="vip9Buy" :disabled='vip9BuyDisabled' @change='clickVip9Buy'>{{msg('物流公司')}}</el-checkbox>
            </el-form-item>
        </el-col>
    </el-row>
    
    <h3>{{msg('用户扩展资料')}}</h3>
    <el-row v-if="user.profile">
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
            <el-form-item :label="msg('其他姓名')">
                <el-input v-model="user.profile.otherName"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
            <el-form-item :label="msg('出生年月')">
                <el-date-picker v-model="user.profile.birthday" class="query-date" type="date" :placeholder="this.msg('选择日期')"
                        format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                    >
                </el-date-picker>
            </el-form-item>
        </el-col>
   
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
            <el-form-item :label="msg('性别')">
                <el-select v-model="user.profile.gender"   class="query-select">
                    <el-option v-for="(d, idx) in dicts.genders"
                        :label="d.label" :value="d.value" :key="idx">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="6" :lg="6">
            <el-form-item :label="msg('应急电话')" >
                <el-input v-model.trim="user.profile.tel"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <el-row v-if="user.profile">
        <el-col :xs="24" :sm="16" :lg="16" v-if="hasPermission('user:profileEdit')">
            <el-form-item :label="msg('备注')">
                <el-input v-model="user.profile.remark" type="textarea" :rows="3"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <h3>{{msg('联系方式')}}</h3>
    <span v-for="(contact, index) in user.contacts" :key="index">
        <div class="detail-span">
        <el-form :model="user.contacts[index]" label-width="80px" size="mini">
            <el-row>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('名称')">
                        <el-input v-model="user.contacts[index].label"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('用户联系方式1')" label-width="110px">
                        <el-input v-model="user.contacts[index].xvalue"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('用户联系方式2')" label-width="110px">
                        <el-input v-model="user.contacts[index].xvalue1"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('是否默认')" >
                        <el-select v-model="user.contacts[index].defaulted"   class="query-select">
                            <el-option label="否" :value="0" ></el-option>
                            <el-option label="是" :value="1" ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :md="6" :lg="6">
                    <el-form-item :label="msg('操作')" >
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="() =>  {user.contacts.splice(index, 1); }">删除</el-button>
                    </el-form-item>
                </el-col>
            </el-row>   
        </el-form>
        </div>
    </span>
    <div style="text-align:center; margin-top:5px;" >
        <el-button type="primary" size="mini" plain @click="addContacts()">
            <i class="el-icon-circle-plus-outline"></i>添加更多
        </el-button>
    </div>

    <h3>{{msg('联系地址')}}</h3>
    <span v-for="(address, index1) in user.addresses" :key="'address'+index1">
        <div class="detail-span">
        <el-form :model="user.addresses[index1]" label-width="80px" size="mini">
            <el-row>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('联系人')">
                        <el-input v-model="user.addresses[index1].name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('电话')">
                        <el-input v-model.trim="user.addresses[index1].tel"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('配送方式')">
                        <el-select v-model="user.addresses[index1].expCompany" class="query-select">
                            <el-option v-for="(d, idx) in expCompanys"
                                :label="d.label" :value="d.value" :key="idx">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('门店名称')">
                        <el-input v-model="user.addresses[index1].shopName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('门/店/区号')">
                        <el-input v-model.trim="user.addresses[index1].shopCode"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="8" :lg="24">
                    <el-form-item :label="msg('详细地址')">
                        <el-input v-model="user.addresses[index1].detail"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('是否默认')" >
                        <el-select v-model="user.addresses[index1].defaulted"   class="query-select">
                            <el-option label="否" :value="0" ></el-option>
                            <el-option label="是" :value="1" ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :md="6" :lg="6">
                    <el-form-item :label="msg('操作')" >
                        <el-button type="danger" icon="el-icon-delete" size="mini" 
                            @click="() =>  {user.addresses.splice(index1, 1); }">删除</el-button>
                    </el-form-item>
                </el-col>
            </el-row>   
        </el-form>
        </div>
    </span>
    <div style="text-align:center; margin-top:5px;">
        <el-button type="primary" size="mini" plain @click="addAddresses()">
            <i class="el-icon-circle-plus-outline"></i>添加更多
        </el-button>
    </div>

    <h3>{{msg('银行卡信息')}}</h3>
    <span v-for="(bank, index2) in user.banks" :key="'banks'+index2">
        <div class="detail-span">
        <el-form :model="user.banks[index2]" label-width="80px" size="mini">
            <el-row>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('银行名称')">
                        <el-input v-model="user.banks[index2].bankName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('开户网点')">
                        <el-input v-model="user.banks[index2].bankAddress"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('银行卡号')">
                        <el-input v-model.trim="user.banks[index2].bankCode"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('开户名')">
                        <el-input v-model="user.banks[index2].bankUser"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :lg="8">
                    <el-form-item :label="msg('是否默认')" >
                        <el-select v-model="user.banks[index2].defaulted"   class="query-select">
                            <el-option label="否" :value="0" ></el-option>
                            <el-option label="是" :value="1" ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :md="6" :lg="6">
                    <el-form-item :label="msg('操作')" >
                        <el-button type="danger" icon="el-icon-delete" size="mini" 
                        @click="() =>  {user.banks.splice(index2, 1); }">删除</el-button>
                    </el-form-item>
                </el-col>
            </el-row>   
        </el-form>
        </div>
    </span>
    <div style="text-align:center; margin-top:5px;">
        <el-button type="primary" size="mini" plain @click="addBanks()">
            <i class="el-icon-circle-plus-outline"></i>添加更多
        </el-button>
    </div>

    <div style="text-align:center; margin-top: 15px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>

    <el-popover ref="deptPopover" width="300" trigger="focus"
            placement="bottom" v-model="deptPopoverVisiable"
        >
        <div>
            <el-input
                :placeholder="msg('输入关键字进行过滤')" clearable size="mini"
                v-model="deptFilterText">
            </el-input>
            <el-scrollbar style="height:400px;" >
                <el-tree :data="depts" :default-expanded-keys="expands" node-key="id" 
                    :filter-node-method="filterNode" ref="deptTreeRef"
                    @node-click="selectedDeptNode">
                </el-tree>
            </el-scrollbar>
        </div>    
    </el-popover>
</el-form> 
</template>

<script>
import Province from "../../plugins/cities.json";

export default {
    name: 'userEdit',
    data(){
        return{
            deptPopoverVisiable:false,
            defaults1: [{label: "是", value:  1}, {label: "否", value: 0}],
            roles: [],
            depts:[],
            provinces: Province,
            cities: [],
            districts: [],
            user: null,
            role: {},
            checked:false,
            roleName:null,
            userEditStatuses:[],
            vipTypes:[],

            rebackChecked:false,
            freeShop:false,
            locked:false,

            vip1Buy:false,
            vip2Buy:false,
            vip3Buy:false,
            vip9Buy:false,
            vipBuyDisabled:false,
            vip9BuyDisabled:false,
            autoDept:false,
            deptLabelShow:true,

            deptFilterText: null,
            expands:[],
            expCompanys:[],
            vipKeyTypes:[
                {label: "试用会员", value:  3},
                {label: "长期会员", value:  1},
                {label: "取消会员", value:  2},
            ]
        }
    },
    mounted: function(){
        this.userEditStatuses = this.dicts.userEditStatuses;
        this.vipTypes = this.dicts.vipTypes;
        this.getCompanys();
    },
    watch: {
      deptFilterText(val) {
        this.$refs.deptTreeRef.filter(val);
      }
    },
    computed: {
    },
    props:{
      type: Number,  //类型： 0-正常编辑，1-用户编辑， 2-添加
    },
    methods:{
        initForm(userId, type){ //初始化表单数据
            this.user = {};
            this.deptLabelShow = true;
            if(userId){
                this.getUser(userId);
            }else{
                this.rebackChecked = false;
                this.freeShop = false;
                this.locked = false;
                this.vip1Buy = false;
                this.vip2Buy = false;
                this.vip3Buy = false;
                this.autoDept = false;
                this.user = {
                    id: null,
                    username: null,
                    password: null,
                    repassword: null,
                    fullname: null,
                    status: 5,
                    roleId: null,
                    roleLabel: null,
                    deptId: null,
                    deptLabel: null,
                    weight: 0,
                    testVip: 0,
                    pkgType:0,
                    userTag:null,
                    pageTag:null,
                    storeTag:null,
                    vipKey:null,
                    vipKeyType:null,
                    profile: {
                        otherName: '',
                        birthday: null,
                        gender: 2,
                        tel: '',
                        remark: ''
                    },
                    addresses: [
                        // {
                        //     userId: this.user.id || null,
                        //     name:"",
                        //     tel: "",
                        //     province: "",
                        //     city: "",
                        //     district: "",
                        //     defaulted: 0,
                        // }
                    ],    
                    banks: [
                        // {
                        //     userId: this.user.id || null,
                        //     bankName: "",
                        //     bankAddress: "",
                        //     bankCode: "", 
                        //     bankUser: "",
                        //     defaulted: 0,
                        // }
                    ],    
                    contacts: [
                        // {
                        //     userId: this.user.id || null,
                        //     label: "",
                        //     xvalue: "",
                        //     xvalue1: "",
                        //     defaulted: 0
                        // }
                    ],    
                };
                this.role = {id: null, label: null};
            }
            this.getRoles();
            this.getDepts();
        },
        getCompanys(){
            this.$axios.get(this.$kit.api.getExpCompany)
                .then((result)=> {
                    if(result.status){
                        this.expCompanys = result.data;
                    }
                });
        },
        clickVipBuy(value){
            if(value){
                this.vip9Buy = false;
                this.vip9BuyDisabled = true;
            }else{
                if(!this.vip1Buy && !this.vip2Buy && !this.vip3Buy){
                    this.vip9BuyDisabled = false;
                }
            }
        },
        clickVip9Buy(value){
            if(value){
                this.vip1Buy = false;
                this.vip2Buy = false;
                this.vip3Buy = false;
                this.vipBuyDisabled = true;
            }else{
                this.vipBuyDisabled = false;
            }
        },
        selectedRole(val){
            if(val){
                if(this.roles && this.roles.length > 0){
                    this.roles.forEach(e => {
                        if(e.id == val){
                            this.user.roleLabel = e.label;
                            this.user.roleName = e.xvalue;
                        }
                    })
                }
            }
        },
        changeAutoDept(){
            if(this.autoDept){

                this.user.deptId = null;
                this.user.deptLabel = null;
                this.user.deptPath = null;
                this.user.deptLabelPath = null;

                this.deptLabelShow = false;
            }else{
                this.deptLabelShow = true;
            }
        },
        clearVipKeyType(){
            this.user.vipKeyType = null
        },
        selectVipKey(){
            if(!this.user.vipKey){
                this.$message(this.msg('请输入密钥'));
                this.user.vipKeyType = null;
                return;
            }
            if(!this.user.roleId){
                this.$message(this.msg('请选择角色'));
                this.user.vipKeyType = null;
                return;
            }
            delete this.user.lastLoginDate;
            delete this.user.weight;
            delete this.user.vipExpire;
            delete this.user.prevLoginDate;
            var _this = this;
            this.$axios.post(this.$kit.api.user.checkVipKey, this.user)
                .then(function(result){
                    if(!result.status){
                        _this.$message(result.msg);
                        _this.user.vipKeyType = null;
                    }
                });
        },
        getUser(userId){ //获取用户数据
            this.$axios.post(this.$kit.api.user.get+"?id="+userId)
                .then((result) => {
                    if(result.status){
                        var u = result.data;
                        if(u.rebacked == 1){
                            this.rebackChecked = true;
                        }else{
                            this.rebackChecked = false;
                        }
                        if(u.freeShop == 1){
                            this.freeShop = true;
                        }else{
                            this.freeShop = false;
                        }
                        if(u.locked == 1){
                            this.locked = true;
                        }else{
                            this.locked = false;
                        }
                        if(u.vipBuyType.indexOf('vip1')>-1){
                            this.vip1Buy = true;
                        }else{
                            this.vip1Buy = false;
                        }
                        if(u.vipBuyType.indexOf('vip2')>-1){
                            this.vip2Buy = true;
                        }else{
                            this.vip2Buy = false;
                        }
                        if(u.vipBuyType.indexOf('vip3')>-1){
                            this.vip3Buy = true;
                        }else{
                            this.vip3Buy = false;
                        }
                        if(u.vipBuyType.indexOf('vip9')>-1){
                            this.vip9Buy = true;
                        }else{
                            this.vip9Buy = false;
                        }
                        this.autoDept = false;
                        delete u.created;
                        delete u.saleCode;
                        delete u.userPath;
                        delete u.parentId;
                        u['password'] = null;
                        u['repassword'] = null;
                        this.user = u;
                        this.role = this.user.role || {id: null, label:null};
                        if(this.user.weight){
                            this.user.weight = (this.user.weight/1000).toFixed(2)
                        }
                    }else{
                        this.$message(result.msg);
                    }
                });
        },
        getRoles(){ 
            //获取角色数据
            this.$axios.post(this.$kit.api.role.list, {})
                .then((result) => {
                    if(result.status){
                        var data = result.data;
                        this.roles = data;
                    }else{
                        this.$message(result.msg);
                    }
                });
        },
        getDepts(){ 
            //获取角色数据
            this.$axios.post(this.$kit.api.dept.listByDataPath, {})
                .then((result) => {
                    if(result.status){
                        var data = result.data;
                        var rs = this.$kit.buildTree(data, true);
                        this.depts = rs;
                        this.expands = [];
                        this.getExpands(this.depts);

                    }else{
                        this.$message(result.msg);
                    }
                });
        },

        getExpands(depts){
            depts.forEach(e => {
                if(e.children && e.children.length<=10){
                    this.expands.push(e.id);
                    this.getExpands(e.children);
                }
            });
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        changeFullname(value){
            this.roleName = value;
        },
        provinceChange(provinceName,index){
            this.cities = this.provinces.find( p => p.name ==provinceName).cityList;
            this.user.addresses[index].city = null;
            this.user.addresses[index].district = null;
        },
        citiesChange(cityName,index){
            this.districts = this.cities.find( p => p.name ==cityName).areaList;
            this.user.addresses[index].district = null;
        },
        addContacts(){
            this.user.contacts.push({
                userId: this.user.id || null,
                label: "",
                xvalue: "",
                xvalue1: "",
                defaulted: 0
            });
        },
        addBanks(){
            this.user.banks.push({
                userId: this.user.id || null,
                bankName: "",
                bankAddress: "",
                bankCode: "", 
                bankUser: "",
                defaulted: 0,
            });
        },
        addAddresses(){
            this.user.addresses.push({
                userId: this.user.id || null,
                name:"",
                tel: "",
                province: "",
                city: "",
                district: "",
                defaulted: 0,
            }); 
        },
        selectedDeptNode(dept, node, self){
            if(this.hasPermission('user:deptEdit') || this.type == 2){
                this.user.deptId = dept.id;
                this.user.deptLabel = dept.label;
                this.user.deptPath = dept.path;
                this.user.deptLabelPath = dept.labelPath;
            }
            this.deptPopoverVisiable = false;
        },
        onSubmit(){

            if(!this.role && !this.role.id){
                this.$message(this.msg("角色不能为空"));
                return;
            }

            if(this.user.weight){
                this.user.weight = (this.user.weight*1000).toFixed(0);
            }

            var url = null;
            if(this.user.id==null){
                url = this.$kit.api.user.create;
            }else{
                url = this.$kit.api.user.update;
            }

            if(this.rebackChecked){
                this.user['rebacked'] = 1;
            }else{
                this.user['rebacked'] = 0;
            }

            if(this.freeShop){
                this.user.freeShop = 1;
            }else{
                this.user.freeShop = 0;
            }

             if(this.locked){
                this.user.locked = 1;
            }else{
                this.user.locked = 0;
            }

            let vipBuyType;
            if(this.vip1Buy){
                vipBuyType += 'vip1,';
            }
            if(this.vip2Buy){
                vipBuyType += 'vip2,';
            }
            if(this.vip3Buy){
                vipBuyType += 'vip3,';
            }
            if(this.vip9Buy){
                vipBuyType += 'vip9,';
            }
            this.user['vipBuyType'] = vipBuyType;
            this.user['autoDept'] = this.autoDept;

            if(this.user.storeTag && this.user.storeTag.length > 1){
                this.$message(this.msg('面单标识2只能输入一个汉字'));
                return;
            }

            var _this = this;
            this.$axios.post(url, this.user)
                .then(function(result){
                    if(result.status){
                        _this.$message(result.msg || (_this.user.id==null? _this.msg("创建成功"): _this.msg('更新成功')));
                        if(result.status){
                            _this.$emit("close-dg", true);
                        }
                    }else{
                        _this.$message(result.msg || (_this.user.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                    }
                });
        },
    }
}
</script>

<style scoped lang="scss">
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}

@media only screen and (min-width: 768px){
    .el-col-sm-8.spec-cell{
        clear:left;
    }
    .el-col-sm-8.spec-cell1{
        clear:none;
    }
}

@media only screen and (min-width: 1200px){
    .el-col-lg-6.spec-cell {
        clear:none;
    }
    .el-col-lg-6.spec-cell1 {
        clear:left;
    }
}
.detail-span{
    margin-top:5px;
    padding-top:15px;
    padding-right:10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    border: 1px solid #EBEEF5;
}


</style>
