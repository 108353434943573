<!-- 编辑用户状态 -->
<template>
<el-form ref="form" label-width="50px">
    <el-form-item :label="msg('状态')">
        <el-select v-model="status" class="query-select">
            <el-option v-for="d in userEditStatuses" 
                :label="d.label" :value="d.value" :key="d.value">
            </el-option>
        </el-select>
    </el-form-item>

    <div style="text-align:center; margin-top: 15px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>

</el-form> 
</template>

<script>
export default {
  name: 'userEditStatus',
  data(){
    return{
       status:null,
       ids:null,
       userEditStatuses:[],
    }
    
  }, 
  computed:{
  },
  props:{
  },
  mounted: function(){
  },
  methods:{
    init(ids){
        this.userEditStatuses = this.dicts.userEditStatuses;
        this.status = null;
        this.ids = ids;
    },
    onSubmit(){
        var _this = this;
        if(!this.status){
            this.$message("请选择状态");
            return;
        }

        this.$axios.post(this.$kit.api.user.editStatus+"?ids="+this.ids+"&s="+this.status,)
            .then((result)=>{
                if(result.status){
                    _this.$message(result.msg || _this.msg('更新成功'));
                    if(result.status){
                        _this.$emit("close-dg", true);
                    }
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
            }).catch(function (error) {
                console.error(error);
            });
    },
  },
  
}
</script>

<style class="scss" scoped>


</style>
