<template>
<div>
  <el-row>
    <el-tag :key="dept.id" v-for="dept in selectes" 
      style="margin: 10px;"
      closable :disable-transitions="false"
      @close="handleClose(dept)">
        {{dept.label}}<span style="display:inline-block; margin-left:5px;"> ({{dept.path}})</span>
    </el-tag>
  </el-row>
  <el-row style="margin-top:15px;">
    <el-input size="small" v-popover:deptPopover suffix-icon="el-icon-arrow-down" v-model="deptFilterText" ></el-input>
  </el-row>
  <div style="text-align:center; margin-top:15px; margin-bottom:15px;">
      <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
      <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
  </div>

  <el-popover 
          ref="deptPopover"
          placement="bottom"
          width="600"
          trigger="focus"
          v-model="deptPopoverVisiable"
      >
      <div>
        <el-scrollbar style="height:400px;">
          <el-tree :data="deptTree" node-key="id"
              ref="menuTreeSelector"  :default-expanded-keys="deptTreeExpands"
              :filter-node-method="filterNode"
              :props="defaultProps" @node-click="selectedDeptNode">
          </el-tree>
        </el-scrollbar>
      </div>
  </el-popover>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'UserGrantData',
  mounted:function(){},
  data(){
    return{
      userId: null,
      selectes: [],
      deptPopoverVisiable:false,
      defaultProps:{label: "label"}, 
      deptFilterText: null,
    }
  }, 
  props: {
    deptTree: Array, //待选择的组织树
    depts: Array,
    deptTreeExpands: Array
  },
  watch: {
    deptFilterText(val) {
      this.$refs.menuTreeSelector.filter(val);
    }
  },
  methods: {
    buildDataPaths(userId){
      this.selectes = []; 
      this.userId = userId;
      this.deptFilterText =null;
      this.$axios.get(this.$kit.api.user.datapath+"?id="+userId)
        .then((result) => {
            if(result.status){
                let dataPaths = result.data.split("|");
                dataPaths.forEach(d => {
                  let dept = this.depts.find(dept => dept.path == d);
                  if(dept){
                    this.selectes.push(dept);
                  }
                });
            }else{
                this.$message(result.msg);
            }
        });
    }, 
    handleClose(dept){
      let idx = this.selectes.findIndex(s => s.id === dept.id);
      if(idx>-1){
        this.selectes.splice(idx,1);
      }
    },
    filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
    },
    selectedDeptNode(dept, node, self){
      let exist = this.selectes.find(e => {
        var idx = dept.id == e.id;
        return idx === 0;
      });

      if(exist){
        this.$message(this.msg("已存在的（父）节点"));
        return;
      }

      this.selectes.push(dept);
      this.deptPopoverVisiable = false;

    },
    onSubmit(){
      var dps = [];
      if(this.selectes.length>0){
        dps = this.selectes.map(e => e.path);
      }

      this.$axios.post(this.$kit.api.user.grantData+"?id="+this.userId, dps)
        .then((result) => {
          if(result.status){
            this.$message(result.msg || this.msg('更新成功'));
            if(result.status){
              this.$emit("close-dg", true);
            }
          }else{
              this.$message(result.msg || this.msg('更新失败'));
          }
        });
    },
  },

  

}
</script>

<style scoped lang="scss">


</style>
