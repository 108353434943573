<template>
<el-form ref="form" :model="role" label-width="100px" size="mini">
    <el-row>
        <!-- <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('角色名')">
                <el-input v-model="role.label"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('角色号')">
                <el-input v-model="role.xvalue"></el-input>
            </el-form-item>
        </el-col> -->
        <el-col :xs="24" :sm="12" v-if="hasPermission('user:vipBuyType1')">
            <el-form-item :label="msg('普通会员：')">
                <el-checkbox v-model="vip1Buy">{{msg('可购买')}}</el-checkbox>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12" v-if="hasPermission('user:vipBuyType2')">
            <el-form-item :label="msg('高级会员：')">
                <el-checkbox v-model="vip2Buy">{{msg('可购买')}}</el-checkbox>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12" v-if="hasPermission('user:vipBuyType2')">
            <el-form-item :label="msg('超级会员：')">
                <el-checkbox v-model="vip3Buy">{{msg('可购买')}}</el-checkbox>
            </el-form-item>
        </el-col>
    </el-row>

    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>

</el-form> 
</template>

<script>
export default {
  name: 'RoleEdit',
  data(){
    return{
        userIds:null,
        vip1Buy:false,
        vip2Buy:false,
        vip3Buy:false,
    }
  },
  props:{
      role: Object,
  },
  mounted: function(){
  },
  methods:{
      init(ids){
          this.userIds = ids;
          this.vip1Buy = false;
          this.vip2Buy = false;
          this.vip3Buy = false;
      },    
    onSubmit(){

        let vipBuyType;
        if(this.vip1Buy){
            vipBuyType += 'vip1,';
        }
        if(this.vip2Buy){
            vipBuyType += 'vip2,';
        }
        if(this.vip3Buy){
            vipBuyType += 'vip3,';
        }
        var _this = this;
        let para = {
            ids:this.userIds,
            vipBuyType:vipBuyType
        }
        _this.$axios.post(_this.$kit.api.user.updateBuyType,para)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg || _this.msg('更新成功'));
                    if(result.status){
                        _this.$emit("close-dg", true);
                    }
                }else{
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
               
            });
    }
  }
}
</script>

<style scoped lang="scss">
.icon-list{ margin:0px; padding:0px; display:flex; flex-wrap:wrap;  border: 1px solid #eaeefb;}
.icon-list li{cursor:pointer;
        padding:8px; border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        display:flex; flex-direction: column; justify-content: center; align-items:center;
}
.icon-list li i{font-size:18px;} 
.icon-list li:hover{color:#5CB6FF;}


</style>
