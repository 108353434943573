<!-- 用户管理-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" v-if="viewSetting"  v-popover:popover @click="viewsVisiable = !viewsVisiable">
                        <div class="wrap" v-show="true">
                            <span >{{msg("当前视图")+'：'}}{{viewSetting.name}}<i class="el-icon-arrow-right" ></i></span>
                        </div>
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" @click="cliceSearch()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                    <el-link :underline="false" @click="doAdd()" v-if="hasPermission('user:create')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-tianjia" font-size="27px;"/>
                            <span class="txt">{{msg('添加')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doView()" v-if="hasPermission('user:view')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-chakan" font-size="27px;"/>
                            <span class="txt">{{msg('查看')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEdit()" v-if="hasPermission('user:edit')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt">{{msg('编辑')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEditStatus()" v-if="hasPermission('user:editStatus')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-pilianggengxin1" font-size="27px;"/>
                            <span class="txt">{{msg('状态')}}</span>      
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doEditBuyType()" v-if="hasPermission('user:updateBuyType')">
                        <div class="wrap">
                            <icon-svg icon-class="icon-huotaitongbu" font-size="27px;"/>
                            <span class="txt">{{msg('更新')}}</span>      
                        </div>
                    </el-link>
                    <el-link :underline="false" @click="doEditPageTag()" v-if="hasPermission('user:userPageTag')">
                        <div class="wrap">
                            <icon-svg icon-class="icon-xitongshezhi_tuxiang-copy" font-size="27px;"/>
                            <span class="txt">{{msg('更新面单标识')}}</span>      
                        </div>
                    </el-link>
                     <el-link :underline="false" @click="grantData" v-if="hasPermission('user:grantData')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-fenpeishujuquanxian" font-size="27px;"/>
                            <span class="txt">{{msg("分配数据权限")}}</span>      
                        </div>            
                    </el-link> 
                    <el-link :underline="false" @click="doExport()" v-if="hasPermission('user:export')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出列表')}}</span>      
                        </div>         
                    </el-link>
                    <el-link :underline="false" @click="doDelete()" v-if="hasPermission('user:delete')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>     
                    </el-link>
                    <el-link :underline="false" @click="runAs" v-if="hasPermission('user:runAs')">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-zhanghu2" font-size="27px;"/>
                            <span class="txt">{{msg("登录此用户")}}</span>      
                        </div>            
                    </el-link>
                </div> 
            </template>
        </ctrl-box>

        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <template v-for="q in scope.query.queries" >
                        <el-form-item :key="q.id" v-if="q.disabled == 0"
                            :label="q.nlabel? q.nlabel : q.label" >
                                <span v-if="q.type == 0">
                                    <el-input v-if="q.op != 6" v-model="q.dvalue" :placeholder="q.placeholder"  clearable></el-input>
                                    <!-- <el-select v-else v-model="q.dvalues" 
                                        multiple filterable allow-create clearable
                                        size="mini" :placeholder="q.placeholder">
                                    </el-select>  -->
                                    <el-input v-else type="textarea" v-model="q.dvalue" :rows="2" 
                                    :placeholder="q.placeholder"  clearable></el-input>
                                </span>
                                <el-input v-else-if="q.type == 1" v-model="q.dvalue" :placeholder="q.placeholder"  clearable></el-input>
                                <span v-else-if="q.type == 2">
                                    <!-- <el-select v-if="q.op != 6" v-model="q.dvalue" size="mini" clearable :placeholder="q.placeholder">
                                        <el-option v-for="d in getDicts(q.filterOptionKey)" 
                                            :key="d.value" :label="d.label" :value="d.value"></el-option>
                                    </el-select>  -->
                                    <el-select v-if="q.op === 0" v-model="q.dvalue" size="mini" clearable :placeholder="q.placeholder">
                                        <el-option v-for="d in getDicts(q.filterOptionKey)" 
                                            :key="d.value" :label="d.label" :value="d.value"></el-option>
                                    </el-select>
                                    <el-select v-else v-model="q.dvalues" multiple size="mini" clearable :placeholder="q.placeholder">
                                        <el-option v-for="d in getDicts(q.filterOptionKey)" 
                                            :key="d.value" :label="d.label" :value="d.value"></el-option>
                                    </el-select> 
                                </span>
                                <el-date-picker v-if="q.type == 3" clearable
                                    v-model="q.dvalues" value-format="yyyy-MM-dd"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                                <el-date-picker v-if="q.type == 4" clearable
                                    v-model="q.dvalues" value-format="yyyy-MM-dd HH:mm:ss"
                                    type="datetimerange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                        </el-form-item>
                    </template>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="list" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                export-config
                resizable border="full"
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="datas"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator @page-change="handlerPageChange" :query="query" >
            </pagenator>
        </div>

        <el-dialog :title="dgTitle" :close-on-click-modal="false" 
            :visible.sync="dgVisible" width="1000px"  @opened="openedEdit"
            :fullscreen="isMinScreen">
                <edit :type="editType" @close-dg="closeEditDialog" ref="editDg"/>
        </el-dialog>

        <el-dialog :title="msg('更新')" :close-on-click-modal="false" 
            :visible.sync="dgBuyVisible" width="400px"  @opened="openedBuyEdit"
            :fullscreen="isMinScreen">
                <user-edit-buy-type @close-dg="closeEditBuyDialog" ref="editBuyDg"/>
        </el-dialog>

        <el-dialog :title="msg('更新状态')" :close-on-click-modal="false" 
            :visible.sync="dgStatusVisible" width="400px"  @opened="openedStatusEdit"
            :fullscreen="isMinScreen">
                <user-edit-status @close-dg="closeEditStatusDialog" ref="editStatusDg"/>
        </el-dialog>

        <el-dialog :title="dgViewTitle" :close-on-click-modal="false" 
            :visible.sync="dgViewVisible" width="800px"  @opened="openedView"
            :fullscreen="isMinScreen">
                <user-view @close-dg="closeViewDialog" ref="viewDg"/>
        </el-dialog>

        <el-dialog :title="msg('分配数据权限')" width="650px" :visible.sync="grantDataVisiable" :fullscreen="isMinScreen"
            @opened="opendGrantData"
        >
            <grant-data 
                :dept-tree="deptTree" 
                :depts = "depts" :dept-tree-expands="deptTreeExpands"
                v-on:close-dg="closeGrantDataDg" ref="grantData"/>
        </el-dialog>

        <el-popover
            ref="popover"
            width="300"
            placement="bottom"
            v-model="viewsVisiable"
            trigger="hover">
            <div class="views">
                <div class="view-group" v-for="(value, key) in views" :key="key">
                    <h5>{{key}}</h5>
                    <div>
                        <el-link :underline="false" v-for="view in value" :key="view.id" @click="changeViews(view)">{{view.name}}</el-link>
                    </div>
                </div>
            </div>
        </el-popover>

        <el-dialog :title="msg('验证个人安全密码')" :visible.sync="strongPwdVisiable" :close-on-click-modal="false">
          <StrongPwd  v-on:close-dg="kit.strongAuthData.closeEvent" />
        </el-dialog>
    </div>
   
</template>

<script>

import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import Edit from "./UserEdit.vue";
import UserEditStatus from "./UserEditStatus.vue";
import UserEditBuyType from "./UserEditBuyType.vue";
import UserView from "./UserDetail.vue";
import GrantData from "./UserGrantData.vue";
import StrongPwd from "@/views/sys/StrongPwd.vue";

export default {
    name: 'User',
    components: {
        StrongPwd,
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
        Edit: Edit,
        UserEditStatus:UserEditStatus,
        UserEditBuyType:UserEditBuyType,
        UserView:UserView,
        GrantData: GrantData,
    },
    mounted(){
        this.getDefaultView(()=>{
            this.list();
        });
        this.getAllViews();
        this.getDepts();
    },
    data(){
        return {
            query:{
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
                queries: [],
            },
            showQuery: false,
           
            dgBuyVisible:false,
            viewsVisiable: false,
            dgViewVisible:false,
            dgStatusVisible:false,
            columns: [],
            viewSetting: null,
            originCols: null,
            views: null, //用户的所有视图列表

            datas: [],      //展示列表
           
            editType: 2,
            dgTitle: '',
            dgViewTitle:'',
            dgVisible: false,
            curr: null,
            currId: null,

            grantDataVisiable: false,
            deptTree: [],
            deptTreeExpands: [],
            depts: [],
            strongPwdVisiable: false //个人安全密码验证窗
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        //获取默认视图
        getDefaultView(cb){

            this.$axios.get(this.$kit.api.viewSetting.defaultView+"?t="+this.dicts.views.userView)
                .then((result) => {
                    if(result.status){
                        this.buildColumns(result.data.details);
                        this.buildQueries(result.data.queries);
                        this.viewSetting = result.data;

                        if(cb) cb();

                    }
                });
        },
        //初始化表格
        buildColumns(details){
            this.columns = [];
            this.columns.push({type: 'checkbox', minWidth: 60, fixed:'left' },);
            this.columns.push( {field: "id", title: this.msg("ID"), minWidth: 80,sortable: true,
                    filters:[{data: null}],
                    filterRender:{name: 'FilterInput'},},);
            details.forEach(e => {
                var col = {
                    field: e.fieldName, 
                    title: e.nlabel ? e.nlabel : e.label,
                    minWidth: e.width || 100,
                    sortable: e.sortable == 1 ? true : false,
                };
                if(e.fieldName == 'status'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let status = this.dicts.userStatuses.find(d => d.value === row.status);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row.status}</el-tag>
                            ];
                        },
                    }
                }else if(e.colName == 'admin'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let admin = this.dicts.adminTypes.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={admin?admin.type:''}>{admin?admin.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    }
                }else if(e.colName == 'vip'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let vip = this.dicts.vipTypes.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={vip?vip.type:''}>{vip?vip.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    }
                }else if(e.colName == 'gender'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let gender = this.dicts.genders.find(d => d.value === row[e.fieldName]);
                            return [
                                <el-tag type={gender?gender.type:''}>{gender?gender.label:row[e.fieldName]}</el-tag>
                            ];
                        },
                    }
                }else if(e.colName == 'photo_id'){
                    col['slots'] = {
                        default: ({ row }) => {
                            let id = row[e.fieldName]
                            return id?[
                                <el-image  style="width: 60px; height: 60px" src={this.$kit.api.imgView+id} preview-src-list={[this.$kit.api.imgView+id]}></el-image>
                            ]: [id];
                        },
                    }
                }

                if(e.filterType == 'search'){
                    col['filters'] = [{data:''}];
                    col['filterRender'] = {name: 'FilterInput'};
                }else if(e.filterType == 'content'){
                    col['filters'] = [{data: {vals: [], sVal: ''}}];
                    col['filterRender'] = {name: 'FilterContent'};
                }else if(e.filterType == 'type'){
                    col['filters'] = this.getDicts(e.filterOptionKey);
                }else if(e.filterType == 'complex'){
                    col['filters'] = [{data: {type: 'has', isCase: false, name: ''}}];
                    col['filterRender'] = {name: 'FilterComplex'};
                }else if(e.filterType == 'date'){
                    col['filters'] = [{data:''}];
                    col['filterRender'] = {
                        name: 'ElDatePicker', 
                        props: {
                            type: 'daterange', 
                            rangeSeparator: '至', startPlaceholder: '开始日期', 
                            endPlaceholder: '结束日期'
                        }
                    };
                }

                this.columns.push(col);
            });

            this.originCols = details.map(e =>  {
                return {
                    tableName : e.tableName,
                    colName: e.colName,
                    fieldName: e.fieldName,
                    hasAlias: e.hasAlias,
                    tableAlias: e.tableAlias
                }
            });
        },
        //初始化查询条件
        buildQueries(qs){

            qs.forEach(q => {
                if(q.dvalue || q.dvalue === 0){

                    if(q.type == 3){ //3-表示是时间类型
                        let curr = new Date();
                        let prevTime =  curr.getTime() - (q.dvalue * 24 * 3600 * 1000);
                        let prev = new Date(prevTime);
                        q['dvalues'] = [this.$kit.fmtDate(prev), this.$kit.fmtDate(curr)]
                    }else if(q.type == 4){//日期时间类型
                        let curr = new Date();
                        let prevTime =  curr.getTime() - (q.dvalue * 24 * 3600 * 1000);
                        let prev = new Date(prevTime);
                        q['dvalues'] = [this.$kit.fmtDateTime(prev), this.$kit.fmtDateTime(curr)]
                    }else if(q.op == 6 || q.op == 8){ //表示可以有多个值
                        q['dvalues'] = q.dvalue.split(",").map(v => (v*1 || v));
                    }else{
                        let val = q.dvalue;
                        let val1 = val*1;
                        q.dvalue = val1 || val;
                        q['dvalues'] = []; 
                    }
                }else{
                    q['dvalues'] = [];
                }
            });

            this.query.queries = qs;
            return qs;
        },
        //获取所有的用户视图
        getAllViews(){
            this.$axios.post(this.$kit.api.viewSetting.list, {category: this.dicts.views.userView})
                .then((result) => {
                    if(result.status){
                        var viewMap = {};
                        this.views = result.data.forEach(v =>  {
                            var label = this.dicts.viewOnwerTypes[v.creater==0?2:1].label;
                            var list = viewMap[label];
                            if(!list){
                                viewMap[label] = list = [];
                            }

                            list.push(v);
                        })
                        this.views = viewMap;
                    }
                });
        },
        //点击视图，切换到指定视图
        changeViews(view){
            this.viewSetting = view;
            if(!view.details || view.details.length == 0){
                this.$axios.get(this.$kit.api.viewSetting.get+"?id="+view.id)
                    .then((result) => {
                        if(result.status){
                            this.buildColumns(result.data.details);
                            var qs = this.buildQueries(result.data.queries);
                            view['details'] = result.data.details;
                            view['queries'] = qs; //缓存起来
                            this.list();
                        }
                });
            }else{
                this.buildColumns(view.details);
                this.query.queries = view.queries;
                this.list();
            }
            this.viewsVisiable = false;
        },
        cliceSearch(){
            var _this = this;
            _this.kit.strongAuth(function () {
              //执行正常操作
              _this.showQuery = !_this.showQuery;
            }, _this);
        },
        //获取列表数据
        list(refresh){
            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }
            var params = this.$kit.rebuildQuery(this.query);
            params['columns'] = this.originCols;
            //获取查询的数，并且过滤掉空数据，
            let queries = []; 
            this.query.queries.forEach(e => {
                    let values = e.dvalues;
                    let value = e.dvalue;

                    let q = {
                        dvalue: e.dvalue,
                        tableName: e.tableName,
                        colName: e.colName,
                        op: e.op,
                        type: e.type,
                        tableAlias: e.tableAlias
                    }
                    if(q.type == 0 && q.op == 6){
                        if(value){
                            value = value.split(/[\s,]+/);
                            q.dvalue = value.join(",");
                            queries.push(q);
                        }
                    }else{
                        if(q.type == 3 || q.op == 6 || q.op==8 || q.type == 4){
                            if(values && values.length>0){
                                q.dvalue = values.join(",");
                                queries.push(q);
                            }
                        }else if(q.dvalue || q.dvalue === 0){
                            queries.push(q);
                        }
                    }
                });
            params['queries'] = queries;
            this.$axios.post(this.$kit.api.user.list, params)
                .then((result) => {
                    if(result.status){
                        this.datas = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }
                });
        },
        //重置查询条件
        reset(){
            this.$kit.clearQuery(this.query);
            if(this.query.queries){ 
                // this.query.queries.forEach(e => {e.dvalue = null; e.dvalues = []});
                this.query.queries.forEach(e => {
                        if(e.disabled == 0){
                            if(e instanceof Array){
                                e.dvalues = [];
                            }else{
                                e.dvalues = null;
                            }
                            if(e.hasOwnProperty('dvalue')){
                                e.dvalue = null; 
                            }
                        }
                    }
                );
            }
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.list();
        },
        //点击添加按钮
        doAdd(){
            this.editType = 2;
            this.dgTitle = this.msg("新增用户");
            this.curr = null;
            this.currId = null;
            this.dgVisible = true;
        },
        doView(){//点击查看按钮
            var _this = this;

            _this.kit.strongAuth(function () {
              //执行正常操作
              _this.doViewRef();
            }, _this);
        },
        doViewRef(){//点击查看按钮
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            var row = selectes[0];

            this.editType = 0;
            this.curr  = row;
            this.currId = this.curr.id;
            
            this.dgViewTitle = this.msg("查看用户")+":"+this.curr.username;
            this.dgViewVisible = true;
        },
        //点击编辑按钮
        doEdit(){
            var _this = this;
            _this.kit.strongAuth(function () {
              //执行正常操作
              _this.doEditRef();
            }, _this);
        },
        doEditRef(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length > 1){
                this.$message(this.msg('只能编辑一条数据'));
                return;
            }

            this.editType = 0;
            this.curr  = selectes[0];

            this.dgTitle = this.msg("编辑用户")+":"+this.curr.username;
            this.dgVisible = true;
        },
        doEditStatus(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.dgStatusVisible = true;
        },
        doEditBuyType(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.dgBuyVisible = true;
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
        //删除选中项
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let names = selectes.map(e => e.fullname).join(",");

            var _this = this;
            this.$confirm('确定要删除用户:'+names+'吗?', this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.user.delete+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.list(true);
                });
            }).catch((err) => {
                // console.log(err);
            });
        },
        doEditPageTag(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>200){
                this.$message(this.msg('最多选择200条数据'));
                return;
            }
            var _this = this;
            this.$prompt("请输入面单标识", this.msg('提示'), {
                closeOnClickModal:false,
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
            }).then(({ value }) => {
                this.$axios.post(this.$kit.api.user.updatePageTag
                    +"?ids="+selectes.map(e => e.id).join(",")
                    +"&tag="+value
                ).then((result) => {
                    if(result.status){
                        _this.list();
                    }
                    _this.$message(result.msg);
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        closeEditDialog(type){  //编辑弹窗关闭后的回调事件
            if(type){
                this.list();
            }
            this.dgVisible = false;
        },
        closeEditBuyDialog(){  //编辑弹窗关闭后的回调事件
            this.dgBuyVisible = false;
        },
        closeEditStatusDialog(){
            this.list();
            this.dgStatusVisible = false;
        },
        openedEdit(){
            this.$nextTick(() => {
                this.$refs.editDg.initForm(this.curr? this.curr.id : null);
            });
        },
        openedBuyEdit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$nextTick(() => {
                this.$refs.editBuyDg.init(selectes.map(e => e.id).join(","));
            });
        },
        openedStatusEdit(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$nextTick(() => {
                this.$refs.editStatusDg.init(selectes.map(e => e.id).join(","));
            });
        },
        openedView(){
            this.$nextTick(() => {
                this.$refs.viewDg.initForm(this.curr? this.curr.id : null);
            });
        },
        closeViewDialog(type){
            if(type){
                this.list();
            }
            this.dgViewVisible = false;
        },
        //获取当前登录用的组织树
        getDepts(){
            this.$axios.post(this.$kit.api.dept.listByDataPathGrant, this.query)
                .then((result) => {
                    if(result.status){
                        var data = result.data;
                        this.depts = data;
                        this.buildTree(data);
                        this.setExpandDepts(this.deptTree);
                    }
                });
        },

        setExpandDepts(depts){
            depts.forEach(e => {
                if(e.children && e.children.length<=10){
                    this.deptTreeExpands.push(e.id);
                    this.setExpandDepts(e.children);
                }
            });
        },

        buildTree(depts){

            var map = {};
            depts.forEach(m => map[m.id] = m);
            
            for(let key in map){
                var m = map[key];
                if(m.parentId){
                    var parent = map[m.parentId];
                    if(parent){
                        if(!parent['children']){
                            parent['children'] = [];
                        }

                        parent.children.push(m);
                        m.parent = parent;
                    }
                }
            }

            var ms = [];
            for(let key in map){
                let m = map[key];
                if(m.parent == null){
                    ms.push(m);
                }
            }
            this.deptTree = ms;
        },
        grantData(){ 
            // var row = this.$refs.dataTable.getCurrentRecord();
            // if(!row){
            //     }
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            let row = selectes[0];

            this.curr = row;
            this.grantDataVisiable = true;
        },
        opendGrantData(){
            this.$nextTick(() => {
                this.$refs.grantData.buildDataPaths(this.curr.id);
            });
        },
        closeGrantDataDg(type){
             if(type){
                this.list();
            }
            this.grantDataVisiable = false;
        },
        runAs(){
            var _this = this;
            _this.kit.strongAuth(function () {
              //执行正常操作
              _this.runAsRef();
            }, _this);
        },
        runAsRef(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length>1){
                this.$message(this.msg('只能选择一条数据'));
                return;
            }

            let prev = localStorage.getItem("token");

            var account = selectes[0];
            this.$axios.get(this.$kit.api.user.runAs+"?id="+account.id)
                .then((result)=>{
                    if(result.status){
                        let user = result.data.user;
                        let vipBalanceDay = user.vipBalanceDay;
                        if(vipBalanceDay<=0){
                            user.vipDayTxt = "已到期";
                        }else {
                            if(vipBalanceDay <= 31){
                                user.vipDayTxt = vipBalanceDay+"天";
                            }else {
                                user.vipDayTxt = (vipBalanceDay/31).toFixed()+"月";
                            }
                        }

                        localStorage.setItem("user", JSON.stringify(user));
                        let tokens = localStorage.getItem("rtokens");
                        if(!tokens){
                            tokens = [];
                        }
                        
                        tokens.push(prev);
                        localStorage.setItem("rtokens", JSON.stringify(tokens));

                        localStorage.setItem("token", result.data.token);

                        window.location.reload();
                    }else{
                        this.$message(result.msg);
                    }
                });
        },
    }
    
}
</script>

<style scoped lang="scss">
.view-group h5{margin-top:8px; margin-bottom:8px; border-bottom: 1px solid #dcdfe6;}
.view-group >>> .el-link{display:inline-block; padding: 5px 15px;}
</style>
